import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
// import LinearProgress from '@mui/material/LinearProgress';
// import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid2';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { extendTheme, CssVarsProvider } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@mui/material/styles';
import 'moment/locale/fr';
import moment from 'moment';
import axios from 'axios';

export default function Form({ token, settings }) {
  axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  moment.locale('fr');
  const disabledColor = useTheme().palette.mode === 'dark' ? 'white' : 'black';
  const [loading, setLoading] = React.useState(false);
  const [disabledBtn, setDisabledBtn] = React.useState(false);
  const [month, setMonth] = React.useState(moment());
  const [values, setValues] = React.useState({
    tenant: '',
    recipient: '',
    rent: '',
    charges: '',
    other: '',
    folder: '',
    template: '',
  });
  const load = async () => {
    const res = await axios.get('/data');
    setValues(res.data);
  };
  useEffect(() => {
    load();
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const send = async () => {
    setLoading((prevLoading) => !prevLoading);
    const body = {
      tenant: values.tenant,
      recipient: values.recipient,
      rent: values.rent,
      charges: values.charges,
      other: values.other,
      paymentDate: month.format(),
      folder: values.folder,
      template: values.template,
    };
    await axios.post('/receipts', body);
    delete body.paymentDate;
    axios.post('data', body);
    // window.open(
    //   'https://mail.google.com/mail/u/0/#drafts',
    //   '',
    //   'width=600,height=400,left=200,top=200',
    // );
    window.open('https://mail.google.com/mail/u/0/#drafts', '_blank', 'noreferrer');
    setLoading((prevLoading) => !prevLoading);
  };

  const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  useEffect(() => {
    if (
      values.tenant === '' ||
      values.rent === '' ||
      Number.isNaN(values.rent) ||
      values.charges === '' ||
      Number.isNaN(values.charges) ||
      values.other === '' ||
      Number.isNaN(values.other) ||
      !validEmail.test(values.recipient) ||
      month === null
    ) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
  }, [values, month]);
  const sm = useMediaQuery((t) => t.breakpoints.up('sm'));
  return (
    <FormControl>
      <Grid container spacing={{ xs: 2, md: 4 }}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <TextField
            InputLabelProps={{ shrink: values.tenant !== '' }}
            size={sm ? 'medium' : 'small'}
            required
            id='tenant'
            name='tenant'
            label='Tenant'
            fullWidth
            value={values.tenant}
            onChange={handleChange('tenant')}
            error={values.tenant === ''}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <TextField
            InputLabelProps={{ shrink: values.recipient !== '' }}
            size={sm ? 'medium' : 'small'}
            required
            id='recipient'
            name='recipient'
            label='Recipient'
            fullWidth
            value={values.recipient}
            error={!validEmail.test(values.recipient)}
            onChange={handleChange('recipient')}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='fr'>
            <DatePicker
              views={['month', 'year']}
              label='Term'
              value={month}
              onChange={(newValue) => setMonth(newValue)}
              slotProps={{ textField: { fullWidth: true, size: sm ? 'medium' : 'small' } }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid size={{ xs: 12, sm: 3 }}>
          <TextField
            sx={{ input: { textAlign: 'right' } }}
            InputLabelProps={{ shrink: values.rent !== '' }}
            size={sm ? 'medium' : 'small'}
            InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}
            required
            id='rent'
            name='rent'
            label='Rent'
            fullWidth
            value={values.rent}
            error={Number.isNaN(values.rent) || values.rent === ''}
            onChange={handleChange('rent')}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 3 }}>
          <TextField
            sx={{ input: { textAlign: 'right' } }}
            InputLabelProps={{ shrink: values.charges !== '' }}
            size={sm ? 'medium' : 'small'}
            InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}
            required
            id='charges'
            name='charges'
            label='Charges'
            fullWidth
            value={values.charges}
            error={Number.isNaN(values.charges) || values.charges === ''}
            onChange={handleChange('charges')}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 3 }}>
          <TextField
            sx={{ input: { textAlign: 'right' } }}
            InputLabelProps={{ shrink: values.other !== '' }}
            size={sm ? 'medium' : 'small'}
            InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}
            required
            id='other'
            name='other'
            label='Other'
            value={values.other}
            fullWidth
            error={Number.isNaN(values.other) || values.other === ''}
            onChange={handleChange('other')}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 3 }}>
          <TextField
            sx={{
              input: { textAlign: 'right' },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: disabledColor,
              },
            }}
            InputLabelProps={{ shrink: values.other !== '' }}
            size={sm ? 'medium' : 'small'}
            InputProps={{
              endAdornment: <InputAdornment position='end'>€</InputAdornment>,
              style: { fontWeight: 'bold' },
            }}
            id='total'
            name='total'
            label='Total'
            disabled
            value={+values.rent + +values.charges + +values.other}
            fullWidth
          />
        </Grid>
        {settings && (
          <>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                InputLabelProps={{ shrink: values.folder !== '' }}
                size={sm ? 'medium' : 'small'}
                required
                id='folder'
                name='folder'
                label='Folder'
                value={values.folder}
                fullWidth
                error={values.folder === ''}
                onChange={handleChange('folder')}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TextField
                InputLabelProps={{ shrink: values.template !== '' }}
                size={sm ? 'medium' : 'small'}
                required
                id='template'
                name='template'
                label='Template'
                value={values.template}
                fullWidth
                error={values.template === ''}
                onChange={handleChange('template')}
              />
            </Grid>
          </>
        )}
        <Grid size={{ xs: 12, sm: 12 }} container justifyContent='center' sx={{ mb: 0.1, mt: 1 }}>
          <LoadingButton onClick={send} disabled={disabledBtn} loading={loading} loadingPosition='end' endIcon={<SendIcon />} variant='contained'>
            SUBMIT
          </LoadingButton>
        </Grid>
      </Grid>
    </FormControl>
  );
}
Form.propTypes = {
  token: PropTypes.string.isRequired,
  settings: PropTypes.bool.isRequired,
};
